import { SxProps, Theme } from '@mui/material';

export const signInContainerTopMarginSX:SxProps<Theme> = (theme) => ({
	marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
        marginTop: 0,
    },
});

export const signInButtonSX:SxProps<Theme> = (theme) => ({
	width: '220px',
    [theme.breakpoints.down('md')]: {
        marginBottom: '46px',
    },
    '& svg': {
        width: '10px',
        height: 'auto',
        marginLeft: '.25rem',
    },
});

export const allSitesContainerSX:SxProps<Theme> = (theme) => ({
    justifyContent: 'space-between',
    '& .jjk-properties-list': {
        maxWidth: '378px',
    },
    '& .jjk-properties > h2': {
        fontSize: '30px',
        lineHeight: '37.5px',
        color: '#323638',
    },
	[theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& .jjk-properties-list': {
            maxWidth: '340px',
        },
        '& .jjk-properties > h2': {
            fontSize: '26px',
	        lineHeight: '29.9px',
        },
    },
});

export const logonContainerSX:SxProps<Theme> = (theme) => ({
    width: '470px',
	[theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '382px',
    },
});

export const logonDividerSX:SxProps<Theme> = (theme) => ({
	borderLeft: '1px solid #D8DBDD',
    width: '17px',
    height: 'inherit',
    margin: '0 8px',
    [theme.breakpoints.down('md')]: {
        borderLeft: '0px',
        borderTop: '1px solid #D8DBDD',
        width: '100%',
        height: '17px',
        marginBottom: '32px',
    },
});
