/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const loginContainerSX: SxProps<Theme> = {
	p: 3,
	height: '100%',
};
