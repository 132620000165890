/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { FC, useMemo, useState } from 'react';
import { ID } from '@/data/types/Basic';
import { Linkable } from '@/components/blocks/Linkable';
import { logonDividerSX, allSitesContainerSX, signInButtonSX, logonContainerSX, signInContainerTopMarginSX } from '@/components/content/Login/styles/logonForm';
import { useCart } from '@/data/Content/Cart';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { getOktaSignInOrderFormData, useOkta } from '@/data/Content/Okta';
import { useLocalization } from '@/data/Localization';
import { LOGON_FORM_JJK_SITES_LINKS, LOGON_FORM_SIGN_IN } from '@/data/constants/marketing';
import { ProcessedContent } from '@/data/types/Marketing';
import { renderContent } from '@/utils/renderContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/sharp-regular-svg-icons';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { OOBLoginPage } from '@/components/content/Login/parts/OOBLoginPage';

// replaced OOB Commerce login content with Okta login
export const Login: FC<{ id: ID }> = () => {
	const [showCommercePage, setShowCommercePage] = useState(false);
	const LoginRoute = useLocalization('Routes').Login;
	const signInNLS = useLocalization('SignIn');
	const dataMap = (contents: any) => contents?.at(0);
	const { data: contentHtml } = useContentRecommendation(LOGON_FORM_SIGN_IN);
	const signInContent: ProcessedContent | undefined = useMemo(() => dataMap(contentHtml), [contentHtml]);
	const { data: linksContentHtml } = useContentRecommendation(LOGON_FORM_JJK_SITES_LINKS);
	const siteLinksContent: ProcessedContent | undefined = useMemo(() => dataMap(linksContentHtml), [linksContentHtml]);
	const { handleOktaAuthentication } = useOkta();
	const { count } = useCart();
	const handleSignInClick = () => {
		const newOrderFormData = getOktaSignInOrderFormData(count);
		handleOktaAuthentication(newOrderFormData);
	};

	// below code is to handle commerce login
	const hostname =
		typeof window !== 'undefined' && window?.location?.hostname
			? window?.location?.hostname
			: '';
	// To hide Go to Commerce Login btn devEnv=false
	const devEnv = process?.env?.NODE_ENV === 'development' || false;

	const handleShowCMCLogin = () => {
		if (devEnv && hostname === 'localhost') {
			setShowCommercePage(true);
		} else {
			setShowCommercePage(false);
		}
	};

	return showCommercePage ? <OOBLoginPage /> : (
		<>
			{devEnv ? <Button variant='blue-outlined' onClick={handleShowCMCLogin}>{'Go to Commerce Login'}</Button> : null}
			<Grid container sx={signInContainerTopMarginSX}>
				<Grid item xs={12} md={6}>
					<Typography variant={'body3'} component={'div'} sx={logonContainerSX}>
						{renderContent(signInContent)}
					</Typography>
					<Linkable
						type="button"
                        variant="blue-contained"
						href="/"
						onClick={handleSignInClick}
						id={LoginRoute.route.t()}
						data-testid={LoginRoute.route.t()}
						sx={signInButtonSX}
					>
						<>{signInNLS.Continue.t()}  <FontAwesomeIcon icon={faAngleRight} color='#fff' /></>
					</Linkable>
				</Grid>
				<Grid item xs={12} md={6}>
					<Stack direction="row" sx={allSitesContainerSX}>
						<Box sx={logonDividerSX}></Box>
						<Typography variant={'body3'} component={'div'} sx={logonContainerSX}>
							{renderContent(siteLinksContent)}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};
