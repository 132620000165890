/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { StackProps } from '@mui/material';

export const loginB2BLinkStack: StackProps = {
	sx: { textAlign: 'center' },
	alignItems: 'center',
	rowGap: 2,
};
